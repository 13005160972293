<template>
    <section class="invoice-add-wrapper">
        <!-- Alert: No item found -->
        <b-alert variant="danger" :show="orderData === undefined">
            <h4 class="alert-heading">Error fetching order data</h4>
            <div class="alert-body">
                No order found with this order id. Check
                <b-link class="alert-link" :to="{ name: 'ecommerce-order-list' }"> Order List </b-link>
                for other orders
            </div>
        </b-alert>

        <b-col>
            <div v-if="isFetching" class="h-100 d-flex justify-content-center align-items-center">
                <b-spinner style="width: 3rem; height: 3rem" variant="primary" />
            </div>
        </b-col>

        <b-row v-if="orderData" class="invoice-add">
            <!-- Col: Left (Invoice Container) -->
            <b-col cols="12" xl="9" md="8">
                <b-form @submit.prevent>
                    <b-card no-body class="invoice-preview-card">
                        <!-- Header -->
                        <b-card-body class="invoice-padding">
                            <b-col class="pl-0">
                                <div class="d-flex justify-content-start mt-0">
                                    <!-- Header: Left Content -->
                                    <div class="logo-wrapper">
                                        <b-img :src="orderData.logoUrl" width="200px" />
                                    </div>
                                </div>
                                <div>
                                    <h4>Shipping:</h4>
                                    <div
                                        class="d-lg-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
                                    >
                                        <!-- Shipping Left Side Start -->
                                        <b-col class="pl-0">
                                            <div class="mt-1">
                                                <div class="mt-1">
                                                    <p class="font-weight-bolder text-wrap">First Name</p>
                                                    <!-- <p>{{ orderData.shipping.first_name }}</p> -->
                                                    <b-form-input
                                                        v-model="orderData.shipping.first_name"
                                                        class="mt-1"
                                                    />
                                                </div>
                                                <div class="mt-1">
                                                    <p class="font-weight-bolder text-wrap">Last Name</p>
                                                    <!-- <p>{{ orderData.shipping.last_name }}</p> -->
                                                    <b-form-input v-model="orderData.shipping.last_name" class="mt-1" />
                                                </div>
                                                <div class="mt-1">
                                                    <p class="font-weight-bolder text-wrap">Company</p>
                                                    <!-- <p>{{ orderData.shipping.company }}</p> -->
                                                    <b-form-input v-model="orderData.shipping.company" class="mt-1" />
                                                </div>
                                                <div class="mt-1">
                                                    <p class="font-weight-bolder text-wrap">Address Line 1</p>
                                                    <!-- <p>{{ orderData.shipping.address_1 }}</p> -->
                                                    <b-form-input v-model="orderData.shipping.address_1" class="mt-1" />
                                                </div>
                                                <div class="mt-1">
                                                    <p class="font-weight-bolder text-wrap">Address Line 2</p>
                                                    <!-- <p>{{ orderData.shipping.address_2 }}</p> -->
                                                    <b-form-input v-model="orderData.shipping.address_2" class="mt-1" />
                                                </div>
                                            </div>
                                        </b-col>
                                        <!-- Shipping Left Side End -->
                                        <b-col class="pl-0 pl-lg-1">
                                            <!-- Shipping Right Side Start -->

                                            <div class="mt-1">
                                                <p class="font-weight-bolder text-wrap">City</p>
                                                <!-- <p>{{ orderData.shipping.city }}</p> -->
                                                <b-form-input v-model="orderData.shipping.city" class="mt-1" />
                                            </div>
                                            <div class="mt-1">
                                                <div class="mt-1">
                                                    <p class="font-weight-bolder text-wrap">State</p>
                                                    <!-- <p>{{ orderData.shipping.state }}</p> -->
                                                    <b-form-input v-model="orderData.shipping.state" class="mt-1" />
                                                </div>
                                                <div class="mt-1">
                                                    <p class="font-weight-bolder text-wrap">Postcode</p>
                                                    <!-- <p>{{ orderData.shipping.postcode }}</p> -->
                                                    <b-form-input v-model="orderData.shipping.postcode" class="mt-1" />
                                                </div>
                                                <div class="mt-1">
                                                    <p class="font-weight-bolder text-wrap">Country</p>
                                                    <!-- <p>{{ orderData.shipping.country }}</p> -->
                                                    <b-form-input v-model="orderData.shipping.country" class="mt-1" />
                                                </div>
                                                <div class="mt-1">
                                                    <p class="font-weight-bolder text-wrap">Phone</p>
                                                    <!-- <p>{{ orderData.shipping.phone }}</p> -->
                                                    <b-form-input v-model="orderData.shipping.phone" class="mt-1" />
                                                </div>
                                            </div>
                                        </b-col>

                                        <!-- Shipping Right Side End -->
                                    </div>
                                </div>
                            </b-col>
                        </b-card-body>

                        <!-- Spacer -->
                        <hr class="invoice-spacing" />

                        <!-- Invoice Client & Payment Details -->
                        <b-card-body class="invoice-padding">
                            <h4 class="mb-2">Billing:</h4>
                            <div class="d-lg-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
                                <b-col class="pl-0">
                                    <!-- Billing Left Side Start -->
                                    <div class="mt-1">
                                        <div class="mt-1">
                                            <p class="font-weight-bolder text-wrap">First Name</p>
                                            <!-- <p>{{ orderData.billing.first_name }}</p> -->
                                            <b-form-input v-model="orderData.billing.first_name" class="mt-1" />
                                        </div>
                                        <div class="mt-1">
                                            <p class="font-weight-bolder text-wrap">Last Name</p>
                                            <!-- <p>{{ orderData.billing.last_name }}</p> -->
                                            <b-form-input v-model="orderData.billing.last_name" class="mt-1" />
                                        </div>
                                        <div class="mt-1">
                                            <p class="font-weight-bolder text-wrap">Company</p>
                                            <!-- <p>{{ orderData.billing.company }}</p> -->
                                            <b-form-input v-model="orderData.billing.company" class="mt-1" />
                                        </div>
                                        <div class="mt-1">
                                            <p class="font-weight-bolder text-wrap">Address Line 1</p>
                                            <!-- <p>{{ orderData.billing.address_1 }}</p> -->
                                            <b-form-input v-model="orderData.billing.address_1" class="mt-1" />
                                        </div>
                                        <div class="mt-1">
                                            <p class="font-weight-bolder text-wrap">Address Line 2</p>
                                            <!-- <p>{{ orderData.billing.address_2 }}</p> -->
                                            <b-form-input v-model="orderData.billing.address_2" class="mt-1" />
                                        </div>
                                        <div class="mt-1">
                                            <p class="font-weight-bolder text-wrap">City</p>
                                            <!-- <p>{{ orderData.billing.city }}</p> -->
                                            <b-form-input v-model="orderData.billing.city" class="mt-1" />
                                        </div>
                                    </div>
                                    <!-- Billing Left Side End -->
                                </b-col>
                                <b-col class="pl-0 pl-lg-1">
                                    <!-- Billing Right Side Start -->
                                    <div class="mt-1">
                                        <div class="mt-1">
                                            <p class="font-weight-bolder text-wrap">State</p>
                                            <!-- <p>{{ orderData.billing.state }}</p> -->
                                            <b-form-input v-model="orderData.billing.state" class="mt-1" />
                                        </div>
                                        <div class="mt-1">
                                            <p class="font-weight-bolder text-wrap">Postcode</p>
                                            <!-- <p>{{ orderData.billing.postcode }}</p> -->
                                            <b-form-input v-model="orderData.billing.postcode" class="mt-1" />
                                        </div>
                                        <div class="mt-1">
                                            <p class="font-weight-bolder text-wrap">Country</p>
                                            <!-- <p>{{ orderData.billing.country }}</p> -->
                                            <b-form-input v-model="orderData.billing.country" class="mt-1" />
                                        </div>
                                        <div class="mt-1">
                                            <p class="font-weight-bolder text-wrap">Email</p>
                                            <!-- <p>{{ orderData.billing.email }}</p> -->
                                            <b-form-input
                                                v-model="orderData.billing.email"
                                                :state="orderData.billing.email.length > 0"
                                                class="mt-1"
                                            />
                                        </div>
                                        <div class="mt-1">
                                            <p class="font-weight-bolder text-wrap">Phone</p>
                                            <!-- <p>{{ orderData.billing.phone }}</p> -->
                                            <b-form-input v-model="orderData.billing.phone" class="mt-1" />
                                        </div>
                                    </div>
                                    <!-- Billing Right Side End -->
                                </b-col>
                            </div>
                        </b-card-body>
                        <hr class="invoice-spacing" />
                        <b-card-body>
                            <!-- Col: Payment Details -->
                            <b-col class="mt-0 justify-content-start d-xl-flex d-block">
                                <div>
                                    <h4 class="mb-2">Payment Details:</h4>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td class="pr-1">Total Due:</td>
                                                <td>
                                                    <span class="font-weight-bold">
                                                        {{ orderData.total | formatCurrency }}</span
                                                    >
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="pr-1">Status:</td>
                                                <td>{{ resolveOrderStatus(orderData.status) }}</td>
                                            </tr>
                                            <tr>
                                                <td class="pr-1">Payment Method:</td>
                                                <td>{{ orderData.paymentMethodTitle }}</td>
                                            </tr>
                                            <tr v-if="orderData.customerIp">
                                                <td class="pr-1">Customer IP:</td>
                                                <td>{{ orderData.customerIp }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </b-col>
                        </b-card-body>
                        <!-- Items & Fees Section -->
                        <b-card-body class="invoice-padding form-item-section">
                            <div ref="form" class="repeater-form" :style="{ height: trHeight }">
                                <!-- Items -->
                                <b-row v-for="(item, index) in newOrderData" :key="index" ref="row" class="pb-2">
                                    <!-- Item Form -->
                                    <!-- ? This will be in loop => So consider below markup for single item -->
                                    <b-col cols="12">
                                        <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                                        <div class="d-none d-lg-flex">
                                            <b-row class="flex-grow-1 px-1">
                                                <!-- Single Item Form Headers -->
                                                <b-col cols="12" lg="5"> Name </b-col>
                                                <b-col cols="12" lg="3"> Price </b-col>
                                                <b-col cols="12" lg="2"> Quantity </b-col>
                                                <b-col cols="12" lg="2"> Total </b-col>
                                            </b-row>
                                            <div class="form-item-action-col" />
                                        </div>

                                        <!-- Form Input Fields OR content inside bordered area  -->
                                        <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                                        <div class="d-flex border rounded">
                                            <b-row class="flex-grow-1 p-2">
                                                <!-- Single Item Form Headers -->
                                                <b-col cols="12" lg="5">
                                                    <label class="d-inline d-lg-none">Name</label>
                                                    <!--TODO itemsOptions YERINE QUERY ATTIRILACAK OPTIONS ICIN-->
                                                    <v-select
                                                        v-if="item.isInitialOrder"
                                                        v-model="item.name"
                                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                        :options="productOptions"
                                                        label="name"
                                                        disabled
                                                        :clearable="false"
                                                        class="mb-2 item-selector-title"
                                                        placeholder="Not allowed"
                                                        @input="val => updateItemForm(index, val)"
                                                        @focusout="window.dispatchEvent(new Event('resize'))"
                                                        @search="searchProducts"
                                                    >
                                                        <template #option="{ name, price }">
                                                            <p class="p-0 m-0 text-wrap">
                                                                {{ name }}
                                                            </p>
                                                            <p class="text-wrap font-weight-bolder p-0 mt-50 mb-50">
                                                                {{ price | formatCurrency }}
                                                            </p>
                                                        </template>
                                                    </v-select>
                                                    <v-select
                                                        v-else
                                                        v-model="item.name"
                                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                        :options="productOptions"
                                                        label="name"
                                                        :clearable="false"
                                                        class="mb-2 item-selector-title"
                                                        placeholder="Start typing..."
                                                        :filterable="false"
                                                        @search="searchProducts"
                                                        @focusout="window.dispatchEvent(new Event('resize'))"
                                                        @input="val => updateItemForm(index, val)"
                                                    >
                                                        <template #option="{ name, price }">
                                                            <p class="p-0 m-0 text-wrap">
                                                                {{ name }}
                                                            </p>
                                                            <p class="text-wrap font-weight-bolder p-0 mt-50 mb-50">
                                                                {{ price | formatCurrency }}
                                                            </p>
                                                        </template>
                                                    </v-select>
                                                </b-col>
                                                <b-col cols="12" lg="3">
                                                    <label class="d-inline d-lg-none">Price</label>
                                                    <b-form-input
                                                        v-if="index < orderInitialCount"
                                                        v-model="item.price"
                                                        disabled
                                                        type="number"
                                                        class="mb-2"
                                                    />
                                                    <b-form-input
                                                        v-else
                                                        v-model="item.price"
                                                        disabled
                                                        type="number"
                                                        class="mb-2"
                                                    />
                                                </b-col>
                                                <b-col cols="12" lg="2">
                                                    <label class="d-inline d-lg-none">Quantity</label>
                                                    <b-form-input
                                                        v-model="item.quantity"
                                                        type="number"
                                                        :state="item.quantity > 0"
                                                        class="mb-2"
                                                    />
                                                </b-col>
                                                <b-col cols="12" lg="2">
                                                    <label class="d-inline d-lg-none">Total</label>
                                                    <p class="mb-1">
                                                        {{ calculateItemTotal(item) | formatCurrency }}
                                                    </p>
                                                </b-col>
                                            </b-row>
                                            <div
                                                class="d-flex flex-column justify-content-between border-left py-50 px-25"
                                            >
                                                <feather-icon
                                                    size="16"
                                                    icon="XIcon"
                                                    class="cursor-pointer"
                                                    @click="removeItem(index)"
                                                />
                                            </div>
                                        </div>
                                    </b-col>
                                </b-row>
                                <!-- Fees -->
                                <b-row
                                    v-for="(fee, index) in orderData.fees"
                                    :key="index + 'fee'"
                                    ref="row"
                                    class="pb-2"
                                >
                                    <!-- Item Form -->
                                    <!-- ? This will be in loop => So consider below markup for single item -->
                                    <b-col cols="12">
                                        <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                                        <div class="d-none d-lg-flex">
                                            <b-row class="flex-grow-1 px-1">
                                                <!-- Single Item Form Headers -->
                                                <b-col cols="12" lg="5"> Name </b-col>
                                                <b-col cols="12" lg="3"> Price </b-col>
                                            </b-row>
                                            <div class="form-item-action-col" />
                                        </div>

                                        <!-- Form Input Fields OR content inside bordered area  -->
                                        <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                                        <div class="d-flex border rounded">
                                            <b-row class="flex-grow-1 p-2">
                                                <!-- Single Item Form Headers -->
                                                <b-col cols="12" lg="5">
                                                    <label class="d-inline d-lg-none">Name</label>
                                                    <!-- Editing disabled for previous fees -->
                                                    <!-- <b-form-input
                            v-if="index < feeInitialCount"
                            v-model="fee.name"
                            type="text"
                            class="mb-2"
                            placeholder="Fee name"
                            disabled
                          >
                            {{ fee.name }}
                          </b-form-input> -->
                                                    <b-form-input
                                                        v-model="fee.name"
                                                        type="text"
                                                        class="mb-2"
                                                        placeholder="Fee name"
                                                    >
                                                        <!-- @input="val => updateFeeForm(index, val)" -->
                                                        {{ fee.name }}
                                                    </b-form-input>
                                                </b-col>
                                                <b-col cols="12" lg="3">
                                                    <label class="d-inline d-lg-none">Price</label>
                                                    <!-- <b-form-input
                            v-if="index < feeInitialCount"
                            v-model="fee.amount"
                            disabled
                            type="number"
                            class="mb-2"
                          /> -->
                                                    <b-form-input v-model="fee.total" type="number" class="mb-2" />
                                                </b-col>
                                            </b-row>
                                            <div
                                                class="d-flex flex-column justify-content-between border-left py-50 px-25"
                                            >
                                                <feather-icon
                                                    size="16"
                                                    icon="XIcon"
                                                    class="cursor-pointer"
                                                    @click="removeFee(index)"
                                                />
                                            </div>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                            <div class="d-flex justify-content-between">
                                <b-button
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    size="sm"
                                    variant="primary"
                                    @click="addNewItemInItemForm"
                                >
                                    Add Item
                                </b-button>
                                <b-button
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    size="sm"
                                    class="ml-3"
                                    variant="primary"
                                    @click="addNewFeeInFeeForm"
                                >
                                    Add Fees
                                </b-button>
                            </div>
                        </b-card-body>

                        <!-- Invoice Description: Total -->
                        <b-card-body class="invoice-padding pb-1 d-flex justify-content-end">
                            <div class="w-100 text-right">
                                <div
                                    v-for="(item, index) in orderData.fees"
                                    :key="'fees' + index"
                                    class="invoice-total-item"
                                >
                                    <p class="font-weight-bolder">
                                        {{ item.name ? item.name : "Untitled Fee" }}
                                    </p>
                                    <p>
                                        {{ (item.total ? item.total : 0) | formatCurrency }}
                                    </p>
                                </div>
                                <hr class="my-50" />
                                <div class="invoice-total-item">
                                    <p class="invoice-total-title font-weight-bold">Total:</p>
                                    <p v-if="orderData.fees" class="invoice-total-amount">
                                        {{ calculateOrderTotal(newOrderData, orderData.fees) | formatCurrency }}
                                    </p>
                                </div>
                            </div>
                        </b-card-body>
                    </b-card>
                </b-form>
            </b-col>

            <!-- Right Col: Card -->
            <b-col cols="12" md="4" xl="3" class="invoice-actions">
                <b-card>
                    <!-- Order Status Updater -->
                    <div class="d-flex justify-content-between align-items-center">
                        <h5>Order Status:</h5>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                        <!-- <v-select v-model="orderData.status" :options="orderStatusOptions" /> -->
                        <v-select
                            v-model="orderData.status"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="orderStatusOptions"
                            :reduce="val => val.value"
                            class="w-100"
                            :clearable="false"
                            placeholder="Select Status"
                        >
                            <template #selected-option="{ label }">
                                <span class="text-wrap overflow-hidden">
                                    {{ label }}
                                </span>
                            </template>
                        </v-select>
                    </div>
                    <div class="d-flex justify-content-between align-items-center mt-1">
                        <h5>Payment Method:</h5>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                        <!-- <b-form-select v-model="orderData.paymentMethod" :options="orderPaymentOptions">
                            {{ orderData.paymentMethod }}
                        </b-form-select> -->
                        <v-select
                            v-model="orderData.paymentMethod"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="orderPaymentOptions"
                            :reduce="val => val.value"
                            class="w-100"
                            :clearable="false"
                            placeholder="Select Payment Method"
                        >
                            <template #selected-option="{ label }">
                                <span class="text-wrap overflow-hidden">
                                    {{ label }}
                                </span>
                            </template>
                        </v-select>
                    </div>
                </b-card>
                <!-- Action Buttons -->
                <b-card>
                    <!-- Button: Preview -->
                    <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        class="mb-75"
                        block
                        :to="{
                            name: 'ecommerce-order-preview',
                            params: { id: orderData.id },
                        }"
                    >
                        Order Preview
                    </b-button>
                    <!-- Button: Send Invoice -->
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        v-b-toggle.sidebar-send-invoice
                        variant="success"
                        class="mb-75"
                        block
                        @click="sendOrder"
                    >
                        Save Order
                    </b-button>
                </b-card>
            </b-col>
        </b-row>
    </section>
</template>

<script>
import { onUnmounted, ref, nextTick } from "@vue/composition-api";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import { resolveItemNames } from "@/utils/naming.utils";
import { calculateItemTotal, calculateOrderTotal } from "@/utils/total.utils";
import {
    BAlert,
    BButton,
    BSpinner,
    BCard,
    BCardBody,
    BCol,
    BForm,
    BFormInput,
    BImg,
    // BInputGroup,
    // BInputGroupPrepend,
    BLink,
    BRow,
    VBToggle,
} from "bootstrap-vue";
import vSelect from "vue-select";
// import flatPickr from "vue-flatpickr-component";
import router from "@/router";
import store from "@/store";
import orderStoreModule from "../orderStoreModule";
import { debounce } from "debounce";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BImg,
        BCardBody,
        BSpinner,
        BButton,
        BForm,
        BFormInput,
        // BInputGroup,
        // BInputGroupPrepend,
        BAlert,
        BLink,
        // flatPickr,
        vSelect,
    },
    directives: {
        Ripple,
        "b-toggle": VBToggle,
    },
    mixins: [heightTransition],

    // Reset Tr Height if data changes
    watch: {
        // eslint-disable-next-line func-names
        "orderData.lineItems": function () {
            this.initTrHeight();
        },
        "orderData.shopId": function () {
            this.getPaymentMethods(this.orderData.shopId);
        },
    },
    mounted() {
        this.initTrHeight();
    },
    created() {
        window.addEventListener("resize", this.initTrHeight);
    },
    destroyed() {
        window.removeEventListener("resize", this.initTrHeight);
    },
    methods: {
        addNewItemInItemForm() {
            this.$refs.form.style.overflow = "hidden";
            this.newOrderData.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)));
            this.$nextTick(() => {
                this.trAddHeight(this.$refs.row[0].offsetHeight);
                setTimeout(() => {
                    this.$refs.form.style.overflow = null;
                }, 350);
            });
            window.dispatchEvent(new Event("resize"));
        },
        addNewFeeInFeeForm() {
            this.$refs.form.style.overflow = "hidden";
            this.orderData.fees.push(JSON.parse(JSON.stringify(this.feeFormBlankItem)));
            this.$nextTick(() => {
                this.trAddHeight(this.$refs.row[0].offsetHeight);
                setTimeout(() => {
                    this.$refs.form.style.overflow = null;
                    window.dispatchEvent(new Event("resize"));
                }, 350);
            });
            window.dispatchEvent(new Event("resize"));
        },
        removeItem(index) {
            // this.newOrderData.splice(index, 1);
            this.newOrderData[index].quantity = 0;
            this.trTrimHeight(this.$refs.row[0].offsetHeight);
            if (!this.newOrderData[index].name) {
                this.newOrderData.splice(index, 1);
                window.dispatchEvent(new Event("resize"));
            }
            this.$nextTick(() => {
                window.dispatchEvent(new Event("resize"));
            });
        },
        removeFee(index) {
            if (this.orderData.fees[index].isInitialFee) {
                this.orderData.fees[index].total = 0;
            } else {
                this.orderData.fees.splice(index, 1);
            }
            this.trTrimHeight(this.$refs.row[0].offsetHeight);
            this.$nextTick(() => {
                window.dispatchEvent(new Event("resize"));
            });
        },
        initTrHeight() {
            this.trSetHeight(null);
            this.$nextTick(() => {
                this.trSetHeight(this.$refs.form ? this.$refs.form.scrollHeight : 0);
            });
        },
        resolveOrderStatus(status) {
            if (status === "on-hold") {
                return "On Hold";
            }

            if (status === "cancelled") {
                return "Cancelled";
            }

            if (status === "processing") {
                return "Processing";
            }

            if (status === "delivered") {
                return "Delivered";
            }

            if (status === "completed") {
                return "Completed";
            }

            if (status === "shipped") {
                return "Shipped";
            }

            if (status === "partial-shipped") {
                return "Partial Shipped";
            }
            if (status === "pending") {
                return "Pending";
            }
        },
        resolvePaymentName(payment, payments) {
            payments.forEach(item => {
                if (item.originId === payment) {
                    return item.title;
                }
            });
        },
    },
    setup() {
        const INVOICE_APP_STORE_MODULE_NAME = "ecommerce-order";

        // Register module
        if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
            store.registerModule(INVOICE_APP_STORE_MODULE_NAME, orderStoreModule);
        }
        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
                store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
            }
        });

        const toast = useToast();
        const activeField = ref(null);
        const orderData = ref(null);
        const newOrderData = ref([]);
        const productOptions = ref([]);
        const orderInitialCount = ref(0);
        const feeInitialCount = ref(0);
        const orderPaymentOptions = ref([]);
        const isFetching = ref(true);

        const getPaymentMethods = shopId => {
            store
                .dispatch("ecommerce-order/fetchPaymentMethods", {
                    shopId: shopId,
                })
                .then(paymentMethodsData => {
                    paymentMethodsData.forEach(paymentMethod => {
                        orderPaymentOptions.value.push({
                            value: paymentMethod.originId,
                            label: paymentMethod.title,
                        });
                    });
                })
                .catch(() => {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: "Error getting payment methods",
                            icon: "AlertTriangleIcon",
                            variant: "danger",
                        },
                    });
                });
        };

        const getOrder = async () => {
            const { data } = await store.dispatch("ecommerce-order/fetchOrder", {
                id: router.currentRoute.params.id,
            });

            if (!data || !data.order) {
                return;
            }

            orderData.value = data.order;
            orderInitialCount.value = data.order.lineItems.length;
            feeInitialCount.value = data.order.fees.length;

            markIsInitialOrder(data.order.lineItems);
            markIsInitialFee(data.order.fees);

            newOrderData.value = data.order.lineItems;
            isFetching.value = false;

            // orderPaymentOptions.value = data.paymentMethods.map(paymentMethod => {
            //     return {
            //         label: paymentMethod.title,
            //         value: paymentMethod.originId,
            //     };
            // });

            window.dispatchEvent(new Event("resize"));
        };

        const markIsInitialOrder = itemsArray => {
            itemsArray.forEach(obj => {
                obj.isInitialOrder = true;
            });
        };

        const markIsInitialFee = fees => {
            fees.forEach(obj => {
                obj.isInitialFee = true;
            });
        };

        const getProductsWithQuery = (query, loadingFunc) => {
            store
                .dispatch("ecommerce-order/fetchProducts", {
                    query: query,
                    shopId: orderData.value.shopId,
                })
                .then(res => {
                    productOptions.value = res.products;
                    loadingFunc(false);
                })
                .catch(() => {
                    loadingFunc(false);
                });
        };

        const searchProducts = (search, loading) => {
            if (search.length) {
                loading(true);
                debounce(getProductsWithQuery(search, loading), 500);
            }
        };

        // Template for new products
        // Gets populated using getProducts API
        const itemFormBlankItem = {
            id: null,
            name: null,
            price: null,
            quantity: null,
            originCreateDate: null,
            originUpdateDate: null,
            createDate: null,
            updateDate: null,
            image: null,
            shopId: null,
        };

        const feeFormBlankItem = {
            name: null,
            amount: null,
        };

        const updateItemForm = (index, val) => {
            const {
                id,
                name,
                price,
                quantity,
                originCreateDate,
                originUpdateDate,
                createDate,
                updateDate,
                image,
                shopId,
            } = val;

            newOrderData.value[index].id = id;
            newOrderData.value[index].name = name;
            newOrderData.value[index].price = price;
            newOrderData.value[index].quantity = quantity;
            newOrderData.value[index].originCreateDate = originCreateDate;
            newOrderData.value[index].originUpdateDate = originUpdateDate;
            newOrderData.value[index].createDate = createDate;
            newOrderData.value[index].updateDate = updateDate;
            newOrderData.value[index].image = image;
            newOrderData.value[index].shopId = shopId;
            window.dispatchEvent(new Event("resize"));
        };

        const updateFeeForm = (index, val) => {
            const { name, amount } = val;

            orderData.value.fees[index].name = name;
            orderData.value.fees[index].amount = amount;
            // resize window to scale ui
            window.dispatchEvent(new Event("resize"));
        };

        const triggerActiveField = string => {
            activeField.value = string;
            nextTick(() => {
                if (string !== null) {
                    document.getElementById(string).focus();
                }
            });
        };

        const outFocusActiveField = () => {
            activeField.value = null;
            document.activeElement.blur();
        };

        const sendOrder = async () => {
            if (!orderData.value.billing.email) {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: "Please enter an email address",
                        icon: "AlertTriangleIcon",
                        variant: "danger",
                    },
                });
                return;
            }
            orderData.value.lineItems = newOrderData.value;
            orderData.value.total = calculateOrderTotal(orderData.value.lineItems, orderData.value.fees);
            store
                .dispatch("ecommerce-order/sendOrderEdit", orderData.value)
                .then(() => {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: "Order Edited!",
                            icon: "CheckCircleIcon",
                            variant: "success",
                        },
                    });
                })
                .catch(() => {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: "An error occured!",
                            icon: "AlertTriangleIcon",
                            variant: "danger",
                        },
                    });
                });
        };

        const selectableModifier = (focused, refName) => {
            if (focused) {
                refName.value.disabled = false;
            } else {
                refName.value.disabled = true;
            }
        };

        const orderStatusOptions = [
            {
                value: "on-hold",
                label: "On Hold",
            },
            {
                value: "processing",
                label: "Processing",
            },
            {
                value: "delivered",
                label: "Delivered",
            },
            {
                value: "completed",
                label: "Shipped",
            },
            {
                value: "partial-shipped",
                label: "Partial Shipped",
            },
            {
                value: "pending",
                label: "Pending",
            },
            {
                value: "cancelled",
                label: "Cancelled",
            },
        ];

        // Get Order and Start the Process
        getOrder();
        window.dispatchEvent(new Event("resize"));

        return {
            orderData,
            newOrderData,
            orderInitialCount,
            feeInitialCount,
            markIsInitialOrder,
            triggerActiveField,
            markIsInitialFee,
            calculateItemTotal,
            calculateOrderTotal,
            getOrder,
            productOptions,
            orderPaymentOptions,
            getProductsWithQuery,
            itemFormBlankItem,
            isFetching,
            feeFormBlankItem,
            getPaymentMethods,
            updateItemForm,
            resolveItemNames,
            updateFeeForm,
            sendOrder,
            activeField,
            selectableModifier,
            outFocusActiveField,
            searchProducts,
            orderStatusOptions,
        };
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
    background-color: $product-details-bg;
}

.form-item-action-col {
    width: 27px;
}

.repeater-form {
    // overflow: hidden;
    transition: 0.35s height;
}

.v-select {
    &.item-selector-title,
    &.payment-selector {
        background-color: #fff;

        .dark-layout & {
            background-color: unset;
        }
    }
}

.dark-layout {
    .form-item-section {
        background-color: $theme-dark-body-bg;

        .row .border {
            background-color: $theme-dark-card-bg;
        }
    }
}

.debugger-class-xd {
    outline: 1px solid red;
}

.disabled-form-edit {
    background-color: $input-disabled-bg;
}

.mt-10 {
    margin-top: 0.05rem !important;
    margin-bottom: 0px !important;
}
</style>
